const system = {
    title: 'System',
    ourSystem: 'Our System',
    mission: 'Creating a\n solution for all homes',
    description:
        'We’re building an AI-powered sensor system for real-time drowning detection & alerting in residential homes.',
    mobile: 'Mobile app',
    mobileTitle: 'Kourai App',
    mobileDescription:
        'See the metaspace™️ and real-time camera views, customize your settings, add family member profiles.',
    box: 'Kube',
    boxTitle: 'Kourai Pool Kube',
    boxDescription:
        'A supercomputer that runs Kourai’s proprietary vision recognition software. Much like a Wi-Fi-router, all you need is an outlet and an ethernet port.',
    alarm: 'Alarm',
    alarmTitle: 'Plug-In Alarm',
    alarmDescription:
        'A plug-in alarm that can be installed in any room in your home. This alarm will make noise, as needed, to gain your attention.',
    camera: 'Camera',
    cameraTitle: 'Kourai’s Cameras',
    cameraDescription:
        'Two wireless Kourai cameras that keep a vigilant eye on your pool day and night. Each camera is equipped with a solar panel and is fully powered by the sun.',
    overview: 'Overview',
}

export default system

const terms = {
    title: 'Terms and Privacy',
    header: 'Terms of use for Kourai, Inc.',
    email: 'info@KOURAI.com',
    services: {
        title: 'Our Services',
        following:
            'The following terms and conditions (“Terms”) govern use of all content, services, and products (the “Service”) provided by KOURAI, Inc.  (“KOURAI”) and available on the KOURAI   sites including but not limited to websites and applications (the “Site”). The Service is owned and operated by KOURAI. The Site is intended to be used by users (“User” or “Users”) in connection with the Services.',
        service:
            'The Service is offered subject to your acceptance without modification of all of the terms and conditions contained herein and all other operating rules, policies, and procedures that may be published from time to time on the Site (collectively, the “Agreement”).',
        entering:
            'If you are entering into this Agreement on behalf of a KOURAI or other legal entity, you represent that you have the authority to bind such entity, its affiliates, and all users who access the Service through your account to this Agreement, in which case the terms “you” or “your” shall refer to such entity, its affiliates, and users associated with it. If you do not have such authority, or if you do not agree with these terms and conditions, you must not accept this Agreement and may not use the Service.',
        read: 'PLEASE READ THIS AGREEMENT CAREFULLY BEFORE ACCESSING OUR SITE OR USING THE SERVICE. BY ACCESSING OR USING ANY PART OF THE SITE, YOU AGREE TO BECOME BOUND BY THIS AGREEMENT. THESE TERMS INCLUDE A BINDING ARBITRATION CLAUSE AND A CLASS ACTION WAIVER.',
        disagree:
            'If you do not agree to all the terms and conditions of this Agreement, then you may not access the Service. If these terms and conditions are considered an offer by KOURAI, acceptance is expressly limited to these terms.',
    },
    account: {
        title: 'General Account Terms',
        illegal:
            'You may not use the Service for any illegal or unauthorized purpose. You must not, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright or trademark laws). No matter what else is said in these Terms or anywhere else within the Services, you expressly acknowledge that you have no right, title, or interest to or in any account you create through our Services, and your account is not your property.',
        warrant:
            'By using the Site, you warrant and represent that you are at least 18 years of age and otherwise legally competent to read, understand and accept the provisions of this Agreement. If you are under 18 years of age, you must not use any part of the Site, create an account, or submit any personal information to KOURAI   through the Site.',
        remove: 'KOURAI   may remove Content that violates the Agreement at its sole discretion. Removing Content, or terminating an account are not actions KOURAI   take lightly, and we may take a while to investigate and determine if such actions are warranted. It is at KOURAI’s sole discretion whether to discuss the reasons for taking action against any Content or account.',
    },
    communication: {
        title: 'Communication',
        using: 'By using this Site and our services you give KOURAI   your express permission to contact you via email, messaging, or other electronic or non-electronic forms of communication for all purposes including distribution of marketing and promotional materials.',
        interactive:
            'We provide interactive services on our Site, including, without limitation chat rooms and bulletin boards. We will take reasonable endeavours to assess any possible risks for Users from third parties when they use any interactive service provided on our Site, and we will decide in each case whether it is appropriate to use moderation of the relevant Service (including what kind of moderation to use) in the light of those risks. However, we are under no obligation to oversee, monitor or moderate any interactive service we provide on our Site, and we expressly exclude our liability for any loss or damage arising from the use of any interactive service by a user in contravention of our content standards, whether the Service is moderated or not. Should a difficulty arise, the user should contact us by emailing info@KOURAI.com  immediately.',
    },
    content: {
        title: 'Content',
        make: 'If you make (or allow any third party to make) material available through the Site (any such material, “Content”), you are entirely responsible for the content of, and any harm resulting from, that Content. That is the case regardless of whether the Content in question constitutes text, graphics, an audio file, or computer software.',
        available: {
            description: 'By making Content available, you represent and warrant that:',
            downloading:
                'the downloading, copying, and use of the Content will not infringe the proprietary rights, including but not limited to the copyright, patent, trademark, or trade secret rights, of any third party;',
            employer:
                'if your employer has rights to intellectual property you create, you have either (i) received permission from your employer to post or make available the Content, including but not limited to any software, or (ii) secured from your employer a waiver as to all rights in or to the Content;',
            complied:
                'you have fully complied with any third-party licenses relating to the Content, and have done all things necessary to successfully pass through to end users any required terms;',
            virus: 'the Content does not contain or install any viruses, worms, malware, trojan horses, or other harmful or destructive content;',
            spam: 'the Content is not spam, is not machine- or randomly-generated, and does not contain unethical or unwanted commercial content designed to drive traffic to third party sites or boost the search engine rankings of third party sites, or to further unlawful acts (such as phishing) or mislead recipients as to the source of the material (such as spoofing);',
            porn: 'the Content is not pornographic, does not contain threats or incite violence, and does not violate the privacy or publicity rights of any third party;',
            ad: 'the Content is not getting advertised via unwanted electronic messages such as spam links on newsgroups, email lists, other groups and web sites, and similar unsolicited promotional methods;',
            named: 'the Content is not named in a manner that misleads your readers into thinking that you are another person or KOURAI; and',
            code: 'you have, in the case of Content that includes computer code, accurately categorized and/or described the type, nature, uses, and effects of the materials, whether requested to do so by KOURAI   or otherwise.',
        },
        limiting: {
            description:
                'Without limiting any of those representations or warranties, KOURAI   has the right (though not the obligation) to, in KOURAI ’s sole discretion:',
            refuse: 'refuse or remove any content that, in KOURAI’s reasonable opinion, violates any KOURAI   policy or is in any way harmful or objectionable, or',
            terminate:
                'terminate or deny access to and use of the Service to any individual or entity for any reason. KOURAI   will have no obligation to provide a refund of any amounts previously paid.',
        },
        user: 'As a User, you may deactivate your account at any time. Personally identifying information such as your name, username, and profile photo will no longer be associated with Content you have posted.',
    },
    fees: {
        title: 'Our Fees',
        pay: 'You agree to pay KOURAI   the fees indicated for the Service including indicated deposit. Upon you agreeing to the Agreement, deposit for Services will be charged by KOURAI.',
        payments: {
            title: 'Payments',
            method: 'In order to use certain Site Services, you must provide account information for at least one valid payment method.',
            credit: 'You hereby authorize KOURAI, to run credit card authorizations on all credit cards provided by you, to store credit card and banking or other financial details as your method of payment consistent with our Customer Privacy Statement, and to charge your credit card (or any other payment method) for the Fees and any other amounts owed under the Agreement. To the extent permitted by applicable law and subject to our Customer Privacy Statement, you acknowledge and agree that we may use certain third-party vendors and service providers to process payments and manage your payment method information.',
            providing:
                'By providing payment method information through the Site and authorizing payments with the payment method, you represents, warrants, and covenants that: (a) you are legally authorized to provide such information; (b) you are legally authorized to make payments using the payment method(s); (c) if you are an employee or agent of a KOURAI or person that owns the payment method, you are authorized by the KOURAI or person to use the payment method to make payments; and (d) such actions do not violate the terms and conditions applicable to your use of such payment method(s) or applicable law.',
        },
        refunds: {
            title: 'Refunds',
            description:
                'All of the fees are not non-refundable once our Service ships to User. If the Service is not shipped to User, KOURAI will refund the full deposit.',
        },
        subscriptions: {
            title: 'Subscriptions',
            agree: 'You agree to pay KOURAI the fees indicated for the Subscrition Service. When payments begin, they will be charged on a pre-pay basis and will cover the use of the Subscription Service for the period as indicated. Fees are non-refundable.',
            notify: 'Unless you notify KOURAI seven (7) days before the end of the applicable period that you want to cancel a subscription, your subscription will automatically renew and you authorize us to collect the then-applicable fee for such subscription (as well as any taxes) using any credit card or other payment mechanism we have on record for you.',
            cancel: 'You may cancel your subscription at any time by contacting info@KOURAI.com. Subscription Fees are non-refundable.',
        },
    },
    copyright: {
        title: 'Copyright Policy',
        description:
            'KOURAI respects copyright law and expects its users to do the same. It is KOURAI’s policy to terminate in appropriate circumstances users or other account holders who repeatedly infringe or are believed to be repeatedly infringing the rights of copyright holders.',
    },
    ip: {
        title: 'KOURAI Intellectual Property Ownership',
        description:
            'The Services contain proprietary materials such as logos, text, software, photos, video, graphics, music and sound. KOURAI   is the owner or licensee of all such proprietary material and all intellectual property rights therein, including without limitation, copyrights and trademarks. In using the Services, you agree not to upload, download, distribute, or reproduce in any way any such proprietary materials without obtaining KOURAI’s prior consent.',
    },
    license: {
        title: 'Software License Terms',
        subtitle: 'We Own the Software',
        description:
            'The KOURAI (“Software”) (including any releases, revisions, updates or enhancements to the Software) and any documentation that accompanies or is made available in connection with Software (including any subscription or purchase information, product packaging) (the “Documentation”), is owned by KOURAI or its licensors. This includes all Intellectual Property Rights in and to the Software and Documentation. Any Software that KOURAI provides to you is licensed, not sold to you, and KOURAI reserves all rights to the Software not expressly granted in these Software License Terms.    ',
        comply: 'So long as you comply with the terms and conditions of the Agreement, KOURAI grants to you a limited non-exclusive, non-transferable license, with no right to sublicense, to download and install a copy of the Software on a mobile device, computer or tablet that you own or control and to run such copy of the Software solely for purposes of accessing and using the Services where the corresponding Services are available for your use during the applicable subscription period.',
        copy: 'You may not: (i) copy, modify or create derivative works based on the Software; (ii) distribute, transfer, sublicense, lease, lend or rent the Software to any third party; (iii) reverse engineer, decompile or disassemble the Software; or (iv) make the functionality of the Software available to third parties, except and only to the extent that applicable law expressly permits.',
        install:
            'These Terms allow you to install only one copy of the Software for use on a single computer, mobile device or tablet, unless your subscription to the Services expressly permits you to use Software on more than one device. You may make one copy of the Software for back-up or archival purposes or copy the Software onto the hard disk of your device and retain the original for back-up or archival purposes only.',
        expiration:
            'Upon expiration or any termination of these Terms, you must stop using and destroy all copies of the Software and the Documentation in your possession.',
        measures:
            'There may be technological measures in the Software that are designed to prevent unlicensed or illegal use of the Software. You agree that KOURAI may use these measures to protect KOURAI against Software piracy (e.g. the software may contain enforcement technology that limits the ability to install and uninstall the Software on a device to not more than a finite number of times for a finite number of devices). You may need to activate these technological measures. If so, the Software will only operate for a finite period prior to Software activation by you. During activation, you may be required to provide a unique activation code acKOURAIing the Software and device configuration in the form of an alphanumeric code over the internet to verify the authenticity of the Software. If you do not complete the activation within the finite period or as prompted by the Software, the Software will cease to function until activation is complete; at which time the Software functionality will be restored. If you are not able to activate the Software during the activation process, you may contact KOURAI using the information provided during activation or by the provider of the Software.',
    },
    changes: {
        title: 'Changes',
        description:
            'We reserve the right to modify the Agreement at any time and modified terms are effective upon posting to the Site. We will make reasonable efforts to inform you of these changes via email or through the Service. Your continued use of or access to the Service following the posting of any changes to this Agreement constitutes acceptance of those changes. KOURAI may also, in the future, offer new services and/or features including the release of new tools and resources. Such new features and/or services shall be subject to the terms and conditions of this Agreement.',
    },
    data: {
        title: 'Data',
        description:
            'Users may request data related to their personal account and group(s) respectively. KOURAI   has the right to refuse repetitive or technically difficult requests. For information about how we collect and share user information please refer to our Customer Privacy Statement.',
    },
    warranties: {
        title: 'Disclaimer of Warranties',
        asIs: 'THE SERVICE IS PROVIDED “AS IS” AND "AS AVAILABLE". KOURAI   AND ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, SUBSIDIARIES, AFFILIATES, LICENSORS AND SUPPLIERS HEREBY EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR A PARTICULAR PURPOSE. NEITHER KOURAI OR ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, SUBSIDIARIES, AFFILIATES, LICENSORS NOR SUPPLIERS WARRANT THAT THE OPERATION OF THE SERVICE WILL BE UNINTERRUPTED, ERROR FREE OR WITHOUT BREACHES OF SECURITY.',
        officers:
            'KOURAI, ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, SUBSIDIARIES, AFFILIATES, AND ITS LICENSORS DO NOT REPRESENT OR WARRANT THAT:',
        true: 'THE INFORMATION PROVIDED ON SITE IS TRUE OR CORRECT,',
        meet: 'YOUR USE OF THE SERVICES WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS,',
        error: 'YOUR USE OF THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE OR FREE FROM ERROR,',
        reliable:
            'ANY INFORMATION OBTAINED BY YOU AS A RESULT OF YOUR USE OF THE SERVICES WILL BE ACCURATE OR RELIABLE, OR',
        defects:
            'THAT DEFECTS IN THE OPERATION OR FUNCTIONALITY OF ANY SOFTWARE PROVIDED TO YOU AS PART OF THE SERVICES WILL BE CORRECTED.',
    },
    liability: {
        title: 'Limitation of Liability',
        description:
            'IN NO EVENT WILL KOURAI   ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, SUBSIDIARIES OR AFFILIATES BE LIABLE TO ANY PARTY FOR:',
        direct: {
            description:
                'ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR PUNITIVE, DAMAGES ARISING OUT OF THE USE, AVAILABILITY OR UNAVAILABILITY OF THE SERVICES (INCLUDING BUT NOT LIMITED TO THE UNAVAILABILITY OR CHANGE IN ADVERTISED SERVICES) OR ANY OTHER SERVICES OR GOODS OBTAINED THROUGH USE OF ANY OF THE FOREGOING, OR ANY DATA TRANSMITTED THROUGH THE SERVICE, EVEN IF KOURAI   HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES; AND',
            loss: 'ANY LOSS OR DAMAGE WHICH MAY BE INCURRED BY YOU, INCLUDING BUT NOT LIMITED TO LOSS OR DAMAGE AS A RESULT OF:',
            reliance:
                'ANY RELIANCE PLACED BY YOU ON THE COMPLETENESS, ACCURACY OR EXISTENCE OF ANY ADVERTISING, OR AS A RESULT OF ANY RELATIONSHIP OR TRANSACTION BETWEEN YOU AND ANY ADVERTISER OR SPONSOR WHOSE ADVERTISING APPEARS ON THE SERVICES;',
            changes:
                'ANY CHANGES WHICH KOURAI MAY MAKE TO THE SERVICES, OR FOR ANY PERMANENT OR TEMPORARY CESSATION IN THE PROVISION OF THE SERVICES (OR ANY FEATURES WITHIN THE SERVICES);',
            accurate: 'YOUR FAILURE TO PROVIDE KOURAI   WITH ACCURATE INFORMATION;',
            secure: 'YOUR FAILURE TO KEEP YOUR PASSWORD OR ACCOUNT DETAILS SECURE AND CONFIDENTIAL.',
        },
        injury: 'ANY BODILY INJURY, DEATH, OR PROPERTY DAMAGE THAT MAY OCCUR AS THE RESULT OF SERVICES.',
        not: 'NOTWITHSTANDING THE TERMS, IN NO EVENT SHALL KOURAI   BE HELD LIABLE, FOR ANY AMOUNTS IN EXCESS OF (IN THE AGGREGATE) ONE HUNDRED U.S. DOLLARS ($100.00).  KOURAI   SHALL HAVE NO LIABILITY FOR ANY FAILURE OR DELAY DUE TO MATTERS BEYOND THEIR REASONABLE CONTROL. THE FOREGOING SHALL NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.',
    },
    indemnification: {
        title: 'Indemnification',
        description:
            'You agree to indemnify, release, defend and hold harmless KOURAI   and its directors, officers, agents and employees (collectively, the “KOURAI  parties”) from any claims, causes of action, demands, recoveries, losses, damages (actual and consequential), fines, penalties and other costs or expenses, of any kind or nature, including but not limited to reasonable legal and accounting fees (collectively, the “claims”), known and unknown, arising out of or in any way connected with such disputes, including, without limitation, disputes arising over breach of this agreement, breach of any separate agreement or transaction, and/or the substitution of units. Further, you hereby agree to indemnify, release, defend and hold harmless the KOURAI   parties from any claims brought as a result of your (1) breach of the terms, (2) violation of any law or the rights of a third party, and (3) use of this Site and the Services.',
    },
    dispute: {
        title: 'Dispute Resolution',
        constitutes:
            'This Agreement constitutes the entire agreement between KOURAI   and you concerning the subject matter hereof, and they may only be modified by a written amendment signed by an authorized agent/employee of KOURAI, or by the posting by KOURAI   of a revised version. Any and all disputes arising of this Agreement, termination, or our relationship with you shall be determined by applying laws of the State of California.',
        invalid:
            'If any part of this Agreement is held invalid or unenforceable, that part will be struck, and the remaining portions will remain in full force and effect. A waiver by either party of any term or condition of this Agreement or any breach thereof, in any one instance, will not waive such term or condition or any subsequent breach thereof. You may not assign your rights under this Agreement without written consent from KOURAI; KOURAI   may assign its rights under this Agreement without condition. This Agreement will be binding upon and will inure to the benefit of the parties, their successors and permitted assigns.',
        arbitration: {
            title: 'ARBITRATION',
            dispute: 'Dispute resolution and arbitration',
            agree: 'By using this Site, you agree that any and all disputes, claims or controversies that you may have against KOURAI   arising out of or relating to or connected in any way to (i) your use of this Site (ii) any services obtained from or provided by KOURAI   or (iii) this Agreement (including the interpretation and scope of this clause and the arbitrability of the dispute), shall be resolved exclusively by mandatory, binding arbitration initiated through and administered by the American Arbitration Association ("AAA"). You further agree that arbitration will be conducted by a single arbitrator pursuant to the applicable Rules and Procedures established by AAA, and that any arbitration proceeding, if necessary, will be held in Los Angeles, California or at such other location as may be mutually agreed upon by KOURAI   and you. Arbitration will be subject to the Federal Arbitration Act and not any state arbitration law. The arbitrator shall apply California law consistent with the Federal Arbitration Act, and shall honor claims of privilege recognized at law. In order to initiate arbitration with the AAA, you may be responsible for paying a filing fee to the AAA, however, in the event you are able to demonstrate that the costs of arbitration will be prohibitive as compared to the costs of litigation, KOURAI   will reimburse as much of your filing and hearing fees in connection with the arbitration as the arbitrator deems necessary to prevent the arbitration from being cost-prohibitive. There will be no right or authority for any claims to be arbitrated on a class action or representative basis.',
            understand:
                'YOU UNDERSTAND AND AGREE THAT, BY ACCEPTING THESE TERMS AND CONDITIONS, YOU AND KOURAI   ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION WITH RESPECT TO THE CLAIMS COVERED BY THIS MANDATORY ARBITRATION PROVISION.',
            court: 'You are thus giving up your right to go to court to assert or defend your rights. Your rights will be determined by a neutral arbitrator, and not a judge or jury.',
            claims: 'All claims you bring against KOURAI   must be resolved in accordance with this arbitration provision. All claims filed or brought by you contrary to this provision will be considered improperly filed and void. Should you file a claim contrary to this arbitration provision, KOURAI   will notify you in writing of the improperly filed claim, and you must promptly withdraw the claim.  If you fail to promptly withdraw the claim after receiving written notice from KOURAI, KOURAI   may recover its reasonable attorneys’ fees and costs incurred to enforce this arbitration provision.',
            notice: 'Notice; Process',
            party: 'A party who intends to seek arbitration must first send a written notice of the dispute to the other, by certified mail, Federal Express, UPS, or Express Mail (signature required), or in the event that we do not have a physical address on file for you, by electronic mail ("Notice"). KOURAI‘s  address for Notice is: info@KOURAI.com.',
            nature: 'The Notice must (1) describe the nature and basis of the claim or dispute; and (2) set forth the specific relief sought ("Demand"). We agree to use good faith efforts to resolve the claim directly, but if we do not reach an agreement to do so within 30 days after the Notice is received, you or KOURAI   may commence an arbitration proceeding. During the arbitration, the amount of any settlement offer made by you or KOURAI   shall not be disclosed to the arbitrator until after the arbitrator makes a final decision and award, if any. All documents and information disclosed in the course of the arbitration shall be kept strictly confidential by the recipient and shall not be used by the recipient for any purpose other than for purposes of the arbitration or the enforcement of the arbitrator’s decision and award and shall not be disclosed except in confidence to persons who have a need to know for such purposes or as required by applicable law.',
            enforceability: 'Enforceability',
            invalid:
                'If any part of this arbitration provision is deemed to be invalid, unenforceable or illegal, or otherwise conflicts with the Rules and Procedures established by AAA, then the balance of this arbitration provision shall remain in effect and shall be construed in accordance with its terms as if the invalid, unenforceable, illegal or conflicting provision was not contained herein.',
        },
    },
    miscellaneous: {
        title: 'Miscellaneous',
        compliance: {
            title: 'General Compliance with Laws',
            description:
                'You shall comply with all applicable laws, statutes, ordinances and regulations regarding your use of the Services.',
        },
        headings: {
            title: 'Headings',
            description:
                'Headings are for reference purposes only and in no way define, limit, construe or describe the scope or extent of such section.',
        },
        agreement: {
            title: 'Entire Agreement',
            description:
                'This Agreement constitutes the entire agreement between KOURAI   and you concerning the subject matter hereof, and they may only be modified by a written amendment signed by an authorized agent/employee of KOURAI, or by the posting by KOURAI   of a revised version.',
        },
        law: {
            title: 'Governing Law',
            description:
                'The terms of this Agreement shall be construed in accordance with the substantive laws of the State of California, United States of America, without giving effect to the principles of conflict or choice of law of such state. The original form of this Agreement has been written in English. The parties hereto waive any statute, law, or regulation that might provide an alternative law or forum or might require this Agreement to be written in any language other than English. This Agreement expressly excludes the United Nations Convention on Contracts for the International Sale of Goods.',
        },
        severability: {
            title: 'Severability',
            description:
                'If any part of this Agreement is held invalid or unenforceable, that part will be struck, and the remaining portions will remain in full force and effect.',
        },
        waiver: {
            title: 'Waiver',
            description:
                'A waiver by either party of any term or condition of this Agreement or any breach thereof, in any one instance, will not waive such term or condition or any subsequent breach thereof.',
        },
        assignment: {
            title: 'Assignment',
            description:
                'You may not assign your rights under this Agreement without written consent from KOURAI; KOURAI   may assign its rights under this Agreement without condition. This Agreement will be binding upon and will inure to the benefit of the parties, their successors and permitted assigns.',
        },
        termination: {
            title: 'Termination',
            description:
                'KOURAI   may immediately issue a warning, temporarily suspend, indefinitely suspend or terminate your use of all or any portion of the Services if you breach the Terms, or at any time or for any reason, at the sole discretion of KOURAI.',
        },
        relationship: {
            title: 'No Relationship',
            description:
                'No joint venture, partnership, employment, or agency relationship exist between you, KOURAI   or any third party provider as a result of this Agreement or use of the Service.',
        },
        force: {
            title: 'Force Majeure',
            description:
                'KOURAI   shall not be liable for any delay or failure to perform in connection with any Services, Service or in the performance of any obligation hereunder, if such delay or failure is due to or in any manner caused by acts of God, rebellion, riots, hijacking, insurrection, civil commotion, strikes or labor disputes, fires, floods, laws, regulations, acts, demands or and orders of any government or agency, or any other cause which is beyond the control of KOURAI  . KOURAI   is hereby released by you from any claim or demand for any direct or consequential damages arising out of failure to perform as a result of a force majeure event whether or not herein enumerated or other event referenced above.',
        },
    },
    contact: {
        title: 'Contacting KOURAI',
        description:
            'If you have any questions about these Terms of Service, please contact KOURAI  at info@KOURAI.com',
    },
}

export default terms

const privacy = {
    title: 'Kourai, Inc.  Customer Privacy Statement',
    lastUpdated: 'This privacy statement was last updated on May 23, 2022.',
    valuesYourPrivacy: 'KOURAI, INC.(“KOURAI ”) values your privacy.',
    learnMore:
        'To learn more about what personal and user data KOURAI collects, uses, retains and shares; how we protect your personal data; and what choices and means of control you have over your personal data, please read this statement in its entirety.',
    changes:
        'If we make important changes to this privacy statement, we will post a notice on our website and/or contact our customers as appropriate or as required by applicable law.',
    cover: {
        title: 'What does this privacy statement cover?',
        statement:
            'This statement describes KOURAI’s customer privacy practices for our KOURAI -branded global websites, KOURAI  services, KOURAI  customer sales and marketing activities, and KOURAI  customer services and support functions, among others.  This statement does not apply to KOURAI employee data practices.',
        note: 'Please also note that certain KOURAI websites, or software experiences may have links to third-party websites or services.  In most cases, those third-party resources are subject to the privacy practices and policies of those third parties and not KOURAI.',
    },
    personal: {
        title: 'What personal, user and product data does KOURAI collect and how does KOURAI use and share the personal data it collects?',
        description:
            'KOURAI may collect certain categories of data about you when you use our websites; use our services; receive our marketing communications; interact with our customer service and support teams; and in other contexts.  The data we collect can be broadly categorized as follows:',
        personalData: {
            title: 'Personal Data',
            description:
                'This is individually identifiable data that you may choose to provide us.  This may include basic information such as your email address and phone number, or more sensitive information such as your full date of birth or your payment information.',
        },
        userData: {
            title: 'User Data',
            description:
                'This is data that we collect from users of our services.  User data could include personal data such as your unique KOURAI user ID or account username and password, but sometimes this user data is anonymous or “pseudonymous”, i.e., certain user data that could be made identifiable if associated with personal data.',
        },
        locationData: {
            title: 'Location Data',
            description:
                'This is data we collect that help us determine a location based on your IP address in order to customize our Service and for other uses consistent with this Privacy Statement.',
        },
        uses: 'KOURAI has various uses for your personal and user data you provide to us.  Here are the principal contexts in which we may use, retain and share your personal and user data:',
        website: {
            title: 'Websites and Account Management',
            description:
                'Most often, you may browse our websites without providing any personal data to us.  However, if you choose to establish a user account with KOURAI, engage in e-commerce, sign up for marketing communications, or interact with our customer service teams or participate in a forum on our website, we will likely need to collect some personal or user data from you.  In addition, our web servers automatically collect certain data by default, such as IP addresses and other activity logs.  This type of data is used for website security and site improvement purposes.  Much of that data is not readily identifiable and is usually kept anonymous.  In order to maintain and improve our websites, KOURAI uses third party hosting, content management and security partners, vendors and suppliers.',
        },
        sales: {
            title: 'Sales',
            description:
                'If you decide to purchase a service from KOURAI, we will need to collect certain personal data from you, including payment-related information.  Typically, we use third party partners, vendors and suppliers, such as payment processing companies during our sales.',
        },
        marketing: {
            title: 'Marketing & Social Media',
            description:
                'You may choose to receive KOURAI marketing communications, participate in an event/contest/promotion with us, or connect with KOURAI via social media.  If you do so, we will collect certain personal data about you, such as your contact information, social media profile information and, in order to determine your eligibility for a contest or promotion, your full or partial dates of birth.  KOURAI may share your personal data with our marketing agencies and partners to carry out our marketing campaigns, contests and promotions.  If you wish to unsubscribe from our marketing, please follow the opt-out link in the communications you receive from us; update your contact preferences in your KOURAI account settings; or click here.',
        },
        registration: {
            title: 'Product Registration',
            description:
                'If you use KOURAI services, you may choose to establish a KOURAI user account. A KOURAI user account profile may include your username, password, settings, preferences, contact information, device ID information, and related data.  KOURAI may share this information with other KOURAI subsidiaries and affiliates and vendors and suppliers, such as third-party hosting partners.  Certain KOURAI product settings, preference information, applications history and location-based information may be used by KOURAI product development and improvement and customer service teams if you choose to enable enhanced customer support services.',
        },
        customerService: {
            title: 'Customer Service & Support',
            description:
                'If you choose to interact with KOURAI customer service and support – whether it be via our website, social media, chat, email or phone – KOURAI customer service teams (some of whom work for outsourced vendors and suppliers of KOURAI) may have access to your account information, product information, contact information and payment information.  You may also be required to answer certain security questions to verify your identity and your call or session with KOURAI customer support may be recorded for quality and training purposes.',
        },
        job: {
            title: 'Job Opportunities',
            description:
                'You may choose to apply for employment opportunities at KOURAI  through the KOURAI  website.  If you complete an application for a job at KOURAI, we may collect your contact information, job history info and certain other information that may be required or allowed under applicable laws to screen candidates for employment.  We may share this information with other KOURAI subsidiaries and affiliates and vendors and suppliers who provide us with candidate search and hiring services.',
        },
        followingTable:
            'The following table provides further information on some of the specific types of personal or user data we may collect from you, how we use it and with whom we may share it:',
        table: {
            data: 'DATA COLLECTED',
            purpose: 'PURPOSES OF USE',
            thirdParty: 'THIRD PARTIES WITH WHOM WE MAY SHARE IT',
            name: 'First and Last Name',
            namePurpose:
                'Account Management, Sales, Marketing & Social Media, Customer Service & Support; Job Applications and Employment Screening',
            nameThirdParty:
                'KOURAI subsidiaries and affiliates; Vendors and Suppliers; Marketing agencies who handle campaigns on behalf of KOURAI',
            contact: 'Contact Information (e.g., phone, email, mailing/billing/shipping address)',
            contactPurpose:
                'Account Management, Sales, Marketing & Social Media, Customer Service & Support; Job Applications and Employment Screening',
            contactThirdParty:
                'KOURAI subsidiaries and affiliates; Vendors and Suppliers; Marketing agencies who handle campaigns on behalf of KOURAI',
            payment: 'Payment Information (e.g., credit/debit card) ',
            paymentPurpose: 'Sales, Customer Service & Support',
            paymentThirdParty: 'Vendors and Suppliers',
            username: 'Username and Password',
            usernamePurpose: 'Account Management, Sales, Customer Service & Support',
            usernameThirdParty: 'Vendors and Suppliers',
            birthday: 'Full/Partial Date of Birth',
            birthdayPurpose:
                'Sales,  Marketing & Social Media, Customer Service & Support; Job Applications and Employment Screening',
            birthdayThirdParty:
                'KOURAI  subsidiaries and affiliates; Vendors and Suppliers; Marketing agencies who handle campaigns/contests on behalf of KOURAI',
            answer: 'Answers to Account Security Questions',
            answerPurpose: 'Account Management, Customer Service & Support',
            answerThirdParty: 'Vendors and Suppliers',
            ip: 'Internet Protocol (IP) Addresses',
            ipPurpose:
                'Web, Network and Product Security; Marketing & Social Media; Customer Service & Support',
            ipThirdParty: 'KOURAI  subsidiaries and affiliates; Vendors and Suppliers',
            social: 'Social Media Profile Information',
            socialPurpose: 'Marketing & Social Media, Customer Service & Support',
            socialThirdParty:
                'Vendors and Suppliers; Marketing agencies who handle campaigns/contests on behalf of KOURAI',
            employment: 'Employment Background and Screening Information',
            employmentPurpose: 'Job Applications and Employment Screening',
            employmentThirdParty: 'KOURAI  subsidiaries and affiliates; Vendors and Suppliers',
        },
    },
    cookies: {
        title: 'Does KOURAI use cookies and related technologies on its websites?',
        definition:
            'Cookies are small pieces of data that are sent to your browser from a web server and stored on your computer.  Websites, including KOURAI ’s sites, use cookies to help remind us who you are when you visit our site; to help you navigate our site during your visits; and to save preferences and shopping cart contents for you so you will not have to re-enter them.',
        store: 'KOURAI may also use cookies to store and help track information about you, including for online advertising purposes and specifically to determine which interest-based ads to display. Where required by applicable law, KOURAI will acquire consent from you prior to using certain cookies during your KOURAI  website experience or will offer you the opportunity to choose which types of cookies to enable or disable. ',
        serve: 'To serve and manage cookies, and related technologies such as tags and website data analytics tools, KOURAI  may use products and services hosted by companies such as Google, Facebook and Twitter. These services may collect information, such as your IP address, sent by your browser or via cookies.',
        use: {
            description:
                'The use of such cookies is relatively standard. Most browsers are initially set up to accept them. However, if you prefer not to store cookies, you can choose to:',
            notUse: 'not use our sites',
            notify: 'set your browser to notify you when you receive a cookie',
            refuse: 'set your browser to refuse to accept cookies',
            update: 'update or change your cookie preferences through our website tool here.',
            delete: 'delete our cookies after visiting our site',
            browse: 'browse our site using your browser’s anonymous usage setting (e.g., "Incognito" mode in Chrome)',
        },
        function:
            'You should also understand that some features of the KOURAI website might not function properly if you do not accept cookies.',
        control:
            'If you do not know how to control or delete cookies or would like to opt-out of receiving certain targeted ads based on your browsing history, you may wish to visit https://www.networkadvertising.org/choices/, https://www.aboutads.info/, or https://www.youronlinechoices.eu/ for detailed guidance, including opt-out instructions.',
        optOut: 'If you would like to opt-out of certain third party supported Internet advertising and behavioral targeting services, please visit any of the following:',
        google: 'Google',
        facebook: 'Facebook',
        twitter: 'Twitter',
        adobe: 'Adobe Marketing Cloud',
        additional:
            'For additional information:  https://www.consumer.ftc.gov/articles/how-protect-your-privacy-online',
    },
    children: {
        title: 'Does KOURAI collect personal data from children?',
        description:
            'Kourai’s websites, products and services are intended for adults aged 18 and older.  Typically, if we become aware that we have collected personal data from persons aged 17 and under, we will take appropriate steps to delete and purge that data.',
    },
    howLong: {
        title: 'How long does KOURAI  retain the personal data it collects?',
        description:
            'KOURAI  intends to retain personal data only as long as necessary to complete the purposes of use described above in section 2.  When those purposes have been fulfilled, we will securely delete personal data as appropriate.  However, we may retain your personal data as long as any of the following circumstances apply:',
        account: 'your KOURAI account or marketing subscription is active',
        provide: 'to provide you with services or support that you have requested',
        comply: 'to comply with applicable laws, including for tax and audit purposes',
        enforce: 'to enforce KOURAI ’s rights, including litigation and debt collection',
    },
    sell: {
        title: 'Does KOURAI sell personal data?',
        description:
            'While KOURAI may share personal data it collects pursuant to the purposes described above in section 2, KOURAI does not sell personal data.  If KOURAI did decide to sell personal data at any point, we would provide appropriate notice and consent mechanisms to individuals as required by applicable laws.',
        inAddition:
            'In addition, please note that if KOURAI or any of its businesses or operations were to be sold to another entity at any point, that sale could involve the transfer of personal data held by KOURAI  to the acquiring organization.  In such a circumstance, KOURAI would provide appropriate notice and consent mechanisms to individuals as required by applicable laws.',
    },
    transfer: {
        title: 'Does KOURAI transfer personal data across borders?',
        mayCollect:
            'KOURAI  may collect, process, use, retain and transfer personal data across borders in order to fulfill our purposes of use as described above in section 2.  This may include transfers of personal data to countries that may not have equivalent privacy or data protection laws to those in your own country.',
        ensure: 'However, regardless of where we transfer personal data to, KOURAI  takes appropriate steps to ensure that your personal data enjoys consistent standards of care.  This includes signing contracts between KOURAI  affiliates and with our vendors and suppliers to make sure that both parties are following consistent privacy and security principles, processes and protections.',
        european:
            'For transfers involving European personal data, KOURAI  uses Standard Contractual Clauses with its partners.',
    },
    protect: {
        title: 'How does KOURAI  protect personal data?',
        description:
            'KOURAI  believes that privacy and security go hand-in-hand.  We use appropriate physical, technical, and administrative controls to protect the personal data we are responsible for.  That includes encryption and other enhanced security measures, where appropriate or required.  We also take appropriate steps to make sure that our vendors, suppliers and certain other partners adopt consistent standards of protection for the personal data that KOURAI  is responsible for.',
        however:
            'However, it is important to note that no technology is guaranteed to be invulnerable to threat or attack.  Therefore, please be sure to exercise care when using any website or product.  If technology you use is configured with privacy or security controls or features, please be sure to utilize those.',
    },
    review: {
        title: 'How can I review, correct, amend or delete my personal data?',
        submit: 'Under this privacy statement and certain applicable laws, you may submit a request to KOURAI  to review, correct, amend or to delete your personal data that KOURAI  has about you.  You may also have the right to request a machine-readable copy of your personal data. To submit such a request to KOURAI, please contact us at info@kourai.com.',
        review: 'Please note that we will review, respond to and act upon such requests as soon as possible and in accordance with applicable laws.  We are also required to verify your identity before granting you access to your personal data.  If you have an KOURAI  account, we may verify your identity by requiring you to sign into your account. If you do not have an KOURAI  account, or an email address on file with us, then we may request additional limited information from you in order to verify your identity. Please note that if you do not have an account with KOURAI , or an email address on file, or if we are unable to verify your identity in our records, we may deny your request.',
        agent: 'You may also use an authorized agent to submit a request under this section on your behalf. If you choose to have an authorized agent submit such a request on your behalf, KOURAI  will require: (i) You to provide the authorized agent written permission to do so; and (ii) your agent to verify their identity directly with KOURAI .  KOURAI  may deny a request from an agent that does not meet these requirements.',
        questions:
            'In addition, you have the right to raise questions or complaints with your applicable national data protection authority at any time.',
    },
    contact: {
        title: 'How can I contact KOURAI?',
        description:
            'If you have any additional questions, complaints or concerns regarding privacy at KOURAI . Please feel free to reach us at the following:',
        email: 'Email: info@Kourai.com',
    },
}

export default privacy

import React, { ReactNode, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export interface PageContextProviderProps {
    children?: ReactNode
    pageContext: {
        lang: string
        originalPath: string
    }
}

const PageContext = React.createContext({})

export const PageContextProvider = ({ pageContext, children = '' }: PageContextProviderProps) => {
    const { i18n } = useTranslation()

    useEffect(() => {
        i18n.changeLanguage(pageContext.lang)
    }, [])

    return <PageContext.Provider value={pageContext}>{children}</PageContext.Provider>
}

export const usePageContext = () =>
    React.useContext(PageContext) as PageContextProviderProps['pageContext']

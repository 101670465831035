/**
 * Implement Gatsby's SSR (Server Side Rendering) APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/ssr-apis/
 */

import React, { ReactNode } from 'react'
import './src/i18n/config/i18n'
import './src/styles/index.scss'
import { PageContextProvider, PageContextProviderProps } from './src/components/pageContext'

interface wrapPageElementProps {
    props: {
        pageContext: PageContextProviderProps['pageContext']
    }
    element?: ReactNode
}

// /**
//  * Wrap all pages with a Translation provider and set the language on SSR time
//  */
// eslint-disable-next-line import/prefer-default-export
export const wrapPageElement = ({ element = '', props }: wrapPageElementProps) => {
    const { pageContext } = props
    return <PageContextProvider pageContext={pageContext}>{element}</PageContextProvider>
}

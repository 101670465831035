const homepage = {
    title: 'Home',
    homeTitle: 'Stop drowning',
    homeSubtitle: 'with AI',
    signUpDescription: 'Preparation is Prevention',
    playVideo: 'Play Video',
    videoTitle: 'The Kourai Pool Kube System',
    videoURL: 'https://www.youtube.com/embed/VrDsbE91KaA',
}

export default homepage

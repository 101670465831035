const problem = {
    title: 'Problem',
    header: 'We’re losing the fight against drowning.',
    description:
        'Thousands of families around the globe suffer the loss of a child every year because of drowning. So far, no one has been able to create an effective pool security system to prevent these tragedies.',
    accidentalDeathStat: 'cause of accidental death for children 4 & under',
    drowningStat: 'per day from drowning in the US and 8x that in serious injury',
    childStat: 'of child drownings at home occur during non-swim time',
}

export default problem

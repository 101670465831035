const preorder = {
    title: 'Pre-Order',
    header: 'The Kourai Pool Kube',
    description: "Thank you for your interest in Kourai's Pool Kube!",
    yourSupport: 'Your support could not come at a more critical time.',
    preorder: 'Pre-Order',
    weMust: 'We must act fast',
    leadingCause:
        'Since drowning remains the leading cause of accidental death for children aged four and under, we must act fast.',
    accelerate:
        'Your pre-order will accelerate our ability to design, manufacture, test, and deploy Kourai’s Safety Ecosystem, an AI-powered system for real-time drowning prevention.',
    youReceive: 'Every Pool Kube package will include',
    solutions:
        'As we expand our visual cognition software, you will receive free access to a variety of home-based AI-powered solutions such as:',
    petProtection: 'Pet protection: Alerts for when your pets are in danger of drowning.',
    generalSafety:
        'General safety: Alerts for when family members run, slip, fall, cry, and injure themselves',
    homeSecurity:
        'Full home security: Front door monitoring, entire perimeter protection hardware and software capabilities',
    breakEven:
        'In addition, any future hardware upgrades will become available to you at break-even cost.',
    mission:
        'We hope these gestures signal just how important your pre order is to our mission. Thank you for believing in Kourai’s vision. We look forward ushering in a new age of real home AI.',
    whatYouBuy: 'What you will buy today',
    initialDeposit:
        'A pre-order of Kourai’s safety ecosystem requires an initial deposit (1/2 of the $1500 system cost) and you will be charged for the other half once your system is shipped.',
    depositRefund:
        'Your deposit will be fully refunded if our system does not make it to market for any reason.',
    ourPricing: 'Our pricing',
    highPrice:
        'While we understand this may be a high price point for some, we did not want to give up any quality conditions with our system.',
    critical:
        'While we could have used more inexpensive hardware and processors, we have no interest in risking the effectiveness of our system, especially with a device that is so life critical.',
    keepYou: 'We’ll keep you posted',
    upToDate: 'Quarterly status updates',
    upToDateDescription:
        'After joining our waitlist, you will receive quarterly updates on the status of our development and deployment.',
    moneyBack: 'Money-back guarantee',
    moneyBackDescription:
        'If for any reason, our system does not deploy, you will receive a full refund, no questions asked.',
    moreInfo: 'Lets stay in touch',
    moreInfoDescription:
        'If you have any questions or just want to chat more about the product and company, please reach out to us at info@kourai.com.',
    together: 'Together, we will stop drowning.',
    battle: 'Thank you for investing in the battle against drowning.',
    dealerTitle: 'Dealer’s waitlist',
    dealerDescription:
        'Thank you for your interest in our Kourai Pool Kube! We appreciate your interest and will keep you updated with our development over the coming months.',
    dealerCheckboxLabel: 'I’m interested in becoming a Kourai Pool Kube dealer',
}

export default preorder

const about = {
    title: 'about',
    header: 'AI is at the \n core of all we do',
    house: {
        title: 'Safety & Security',
        description:
            'Kourai uses cutting-edge innovations in artificial intelligence to create unique home safety and security products.',
        bullet1:
            'Future features include pet protection, fall detection, and a full-scale backyard monitoring system',
        bullet2: 'Seamlessly add new cameras to your existing network through our mobile app.',
        bullet3: 'Customizable alarm system and a personal AI',
    },
    safe: {
        title: 'Privacy',
        description:
            'Kourai respects your privacy, meaning you’ll always have complete control of your data.',
        bullet1: 'No cloud computing, all localized processing',
        bullet2: 'Local data storage',
        bullet3: 'No remote access to data without user approval',
    },
    boxes: {
        title: 'Quality',
        description: 'We’ve developed quality standards that reflect the gravity of our mission.',
        bullet1: 'Extensive neural network testing suites',
        bullet2: 'Large beta programs',
        bullet3: 'Military-grade quality assurance system',
    },
    lotus: {
        title: 'Simplicity',
        description:
            'We designed our products to be intuitive and user-friendly, regardless of tech skill or experience.',
        bullet1: 'First ever AI Installation Assistant',
        bullet2: 'Install our system yourself in 5 minutes or less',
        bullet3: 'Never before seen security application interface',
    },
    mascot: {
        title: 'Kourai',
        description: 'Kourai comes from Greek Mythology. In Homer’s Iliad, Hephaestus, created golden automatons to serve the gods on Mount Olympus. This is considered the first mention of “artificial intelligence” in recorded history.',
        quote: '“Hephaestus took up a heavy stick in his hand, and went to the doorway limping. And in support of their master moved his attendants. These are golden, and in appearance like living young women. There is intelligence in their hearts, and there is speech in them and strength, and from the immortal gods they have learned how to do things. These stirred nimbly in support of their master” – Homer’s Iliad',
    },
    meetTheFounders: {
        header: 'Meet the Founders',
        ourTeam: 'Our Team',
        ceo: 'CEO / Founder',
        president: 'President & Co-Founder',
        samuel: 'Samuel Boulanger',
        tyler: 'Tyler Wilson',
        samuelDescription:
            'Previously worked at Intel Corporation as a machine learning engineer. He spearheaded research and development for Intel’s Edge AI Applications department, specializing in identifying, optimizing, and porting the latest computer vision research for Intel’s edge chipsets. Samuel handled artificial intelligence applications with an extensive suite of customers. Samuel now oversees the engineering and development of Kourai’s technology stack.',
        tylerDescription:
            'Tyler Wilson is President and co-founder; he handles sales, marketing, and Kourai operations. Tyler has a lifelong history in pools, even having a near-death drowning scare himself. Tyler has a background in political science, focusing on law and public policy.',
    },
}

export default about

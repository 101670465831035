// This is where you'd add more Q&As and it'll automatically show up on the page. Just follow the format:
// 5: {
//     question: 'Question 6 goes here',
//     answer: 'Answer 6 goes here',
// },

export const QA = {
    0: {
        question: 'Will I receive alerts all the time?',
        answer: 'Our system understands context in and around your pool area. On default settings, you will only be alerted when necessary. You can increase or decrease the sensitivity of alerts in settings or set particular alerts. For example, "Alert me when a child is near the pool without supervision".',
    },
    1: {
        question: 'Are my videos private?',
        answer: 'Yes. We never share customer data with third party companies. Your camera streams are processes and stored locally through a dedicated secure network.',
    },
    2: {
        question: 'What happens if I lose internet connection?',
        answer: 'Kube™️ has a dedicated Wi-Fi network, so even if you lose internet the system will continue to function normally.',
    },
    3: {
        question: 'Is it reliable?',
        answer: 'Yes, the system runs 24/7. To ensure the highest safety standards, we run the system through an exhaustive testing suite. However, we always want to be very clear that the Kourai Pool Alarm System™️ is a back up system and does not replace a vigilant caregiver.'
    },
    4: {
        question: 'Is it difficult to install?',
        answer: 'Installation is fast and simple. Our AI setup assistant will walk you through each step.',
    },
}

const faq = {
    title: 'FAQ',
    ...QA,
}

export default faq

const contact = {
    title: 'Contact',
    header: 'Learn More',
    name: 'Name',
    email: 'Email',
    phone: 'Phone number',
    postal: 'Postal Code',
    company: 'Company',
    city: 'City',
    message: 'Message',
    send: 'Send',
    submit: 'Submit',
    joinWaitlist: 'Join our waitlist',
}

export default contact

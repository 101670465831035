import homepage from './homepage'
import problem from './problem'
import system from './system'
import about from './about'
import contact from './contact'
import preorder from './preorder'
import error404 from './error404'
import thankYou from './thankYou'
import footer from './footer'
import terms from './terms'
import privacy from './privacy'
import faq from './faq'

const en = {
    homepage,
    problem,
    system,
    about,
    contact,
    preorder,
    error404,
    thankYou,
    footer,
    terms,
    privacy,
    faq,
}

export default en
